<template>
	<n-card style="width: 100%; height: 80vh;">

		<n-space vertical class="form-container">

			<n-grid :cols=12>
				<n-gi :span=8>
					<n-input :status="inputState" placeholder=" " v-model:value="userId" v-on:keyup.enter="search">
						<template #prefix>ID</template>
					</n-input>
				</n-gi>
				<n-gi :offset=1 :span=3>
					<n-checkbox v-model:checked="showList">
				      Listă
				    </n-checkbox>
				</n-gi>
			</n-grid>

			<n-date-picker v-model:value="dateRange" type="daterange" />


			<n-button size="tiny" block type="primary" @click="search" :loading="loading">
				Search
			</n-button>
		</n-space>

		<n-spin :show="loading">
			<apexchart v-if="!showList" type="area" :height="getVhInPx(65)" :options="chartOptions" :series="series" />
			<n-data-table v-else
				:loading="loading"
				:columns="tableColumns"
				:data="listData"
				:bordered="false"
				:pagination="paginationReactive"
				max-height="55vh"
			/>
		</n-spin>

	</n-card>
</template>

<script>

import PostService from '../PostService'
import { useMessage } from 'naive-ui'

export default {
	name: 'CashFlow',
	data() {
		const message = useMessage();
		const nowDate = Date.now();
		return {
			message,
			showList: false,
			userId: null,
			inputState: null,
			dateRange: [nowDate - 604800000, nowDate],
			loading: false,

			paginationReactive: {
				page: 1,
				pageSize: 50,
				showSizePicker: true,
				pageSizes: [50, 200, 1000, 10000],
				onChange: (page) => {
					this.paginationReactive.page = page
				},
				onUpdatePageSize: (pageSize) => {
					this.paginationReactive.pageSize = pageSize
					this.paginationReactive.page = 1
				}
			},

			tableColumns: [
				{
					key: "from",
					title: "Acțiune"
				},
				{
					key: "total",
					title: "Total"
				},
				{
					key: "time",
					title: "Dată"
				}
			],
			listData: [],

			series: [
				{
					name: 'Total',
					data: [] // [time, value, actiune]
				},
				{
					name: "Wallet",
					data: [] // [time, value]
				},
				{
					name: "Bank",
					data: [] // [time, value]
				}
			],
			chartOptions: {
				colors: ['#ED6A29', '#16CC35', '#12A1C4'],
				chart: {
					type: 'area',
					stacked: false,
					height: 350,
					zoom: {
						type: 'x',
						enabled: true,
						autoScaleYaxis: true
					},
					toolbar: {
						autoSelected: 'zoom'
					}
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 1
				},
				fill: {
					type: 'gradient',
					gradient: {
						shadeIntensity: 1,
						inverseColors: false,
						opacityFrom: 0.3,
						opacityTo: 0,
						stops: [0, 90, 100]
					}
				},
				grid: {
					borderColor: "#222"
				},
				yaxis: {
					labels: {
						formatter: function (val) {
							return val.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0});
						}
					}
				},
				xaxis: {
					type: 'datetime'
				},
				tooltip: {
					shared: false, // Apare actiunea de 3 ori
					theme: "dark",
					z: {
						title: "Acțiune:"
					},
					x: {
						format: 'dd/MM/yy HH:mm'
					},
					y: {
						formatter: function (val) {
							return val.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0});
						}
					}
				}
			},

		}
	},
	methods: {
		async search() {

			const id = parseInt(this.userId);
			if(!id) {
				this.inputState = "error";
				this.message.error("Este necesar să introduci un id");
				return;
			}
			this.inputState = null;

			this.loading = true;

			let data = await PostService.fetchCashFlow(id, this.dateRange);

			this.series[0].data = data.total;
			this.listData = data.total.map(obj => {
				return {
					// UTC + 3
					time: new Date(obj[0] - (3 * 3600 * 1000)).toLocaleString('ro-RO'),
					total: obj[1].toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}),
					from: obj[2]
				}
			})

			this.series[1].data = data.wallet;
			this.series[2].data = data.bank;

			this.loading = false;
		},
		getVhInPx(viewHeight) {
			return Math.floor(Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) * (viewHeight / 100));
		}
	}
}


</script>


<style scoped>

.form-container {
	width: 18%;
}

@media only screen and (max-width: 1000px) {
	.form-container {
		width: 100%;
	}
}

</style>