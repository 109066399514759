<template>
	<n-config-provider :theme="darkTheme" :theme-overrides="themeOverrides">

		

		<n-spin :show="loading">
			<div v-if="!loading">
				<LogIn v-if="logged === false" :error="error" />
				<div v-else class="table-container">

					<div class="page-header">
						<n-grid :cols=2>
							<n-gi>
								<n-space style="display: flex; align-items: center; padding-block: max(1vh, 5px);">
									<img style="height: 5vh" src="./assets/logo.png"/>
									<span class="furyLogo">fury <span class="desktop-only">logs</span></span>
								</n-space>
							</n-gi>
							<n-gi>
								<n-space justify="end" style="display: flex; align-items: center; height: 100%;">
									<h3 class="desktop-only">{{ logged.username }}</h3>
									<n-avatar round size="medium"
										:src="`https://cdn.discordapp.com/avatars/${logged.id}/${logged.avatar}.${logged.avatar.charAt(1) === '_' ? 'gif' : 'png'}`"
										style="margin-top: 5px; margin-bottom: 0;"
									/>
									<n-popover trigger="hover" placement="bottom">
										<template #trigger>
											<n-button strong circle secondary type="error" @click="logOut">
												<template #icon>
													<n-icon><i class="fa-solid fa-arrow-right-from-bracket"></i></n-icon>
												</template>
											</n-button>
										</template>
										<span>Log out</span>
									</n-popover>
									
								</n-space>
							</n-gi>
						</n-grid>
					</div>
					<n-message-provider>
						<n-tabs :tabs-padding="50" style="width: 90%; margin: 0 auto; margin-top: 1vh;">
							<n-tab-pane name="Home">
								<LogsTable />
							</n-tab-pane>

							<n-tab-pane name="Cash Flow">
								<CashFlow />
							</n-tab-pane>

							<n-tab-pane name="Top 100">
								<TopPlayers />
							</n-tab-pane>
						</n-tabs>
					</n-message-provider>
				</div>
			</div>

		</n-spin>
	</n-config-provider>
</template>

<script>

import LogIn from './components/LogIn.vue'
import LogsTable from './components/LogsTable.vue'
import CashFlow from './components/CashFlow.vue'
import TopPlayers from './components/TopPlayers.vue'

import PostService from './PostService'
import { darkTheme } from 'naive-ui'


export default {
	name: 'App',
	components: {
		LogIn,
		LogsTable,
		CashFlow,
		TopPlayers
	},
	data() {
		return {
			loading: true,
			logged: false,
			error: null,
			darkTheme,
			themeOverrides: {
			    common: {
					primaryColor: '#ED6A29',
					primaryColorHover: "#F09268",
					primaryColorPressed: "#F07E4B",
					primaryColorSuppl: "#F05617"
			    }
			}
		}
	},
	async created() {
		document.title = "Fury Logs - LogIn";
		this.logged = await PostService.checkAuth();

		if(this.logged) {
			if (this.logged.allowed === false) {
				this.error = "Nu ai acces la aceasta pagina";
				this.logged = false;
			} else {
				document.title = "Fury Logs - Home";
			}
		}

		this.loading = false;
	},
	methods: {
		logOut() {
			PostService.logOut();
			this.logged = false;
			document.title = "Fury Logs - LogIn";
		}
	}
}
</script>

<style scoped>
	
.page-header {
	background: rgb(24, 24, 28);
	position: sticky;
	padding-inline: 3vw;
	box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, .4);
	user-select: none;
}

.table-container {
	width: 100vw;
	height: 100vh;
}

</style>

<style>

@media only screen and (max-width: 1000px) and (orientation: portrait) {
	.desktop-only {
		display: none;
	}
}

@font-face {
	font-family: furyFont;
	src: url("./assets/furyLogo.ttf");
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #fafafa;
	background: rgb(16, 16, 20);
	overflow-x: hidden;
}

.furyLogo {
	font-family: furyFont;
	color: #fafafa;
	letter-spacing: 1px;

}
</style>