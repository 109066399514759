import axios from 'axios'

class PostService {

	static fetchLogs(id = "any", dateRange, types = [], params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let url = `api/logs/${id}/${Math.floor(dateRange[0] / 1000)}-${Math.floor(dateRange[1] / 1000)}?types[]=${types.join("&types[]=")}`;

				for (const [key, value] of Object.entries(params)) {
					url = url + `&${key}=${value}`;
				}

				const res = await axios.get(url);
				const data = res.data;

				resolve(
					data.map(log => ({
						userId: log.user_id,
						type: log.type,
						data: log.data,
						date: new Date(log.time * 1000).toLocaleString("ro-RO")
					}))
				);
			} catch(err) {
				reject(err);
			}
		})
	}

	static fetchCashFlow(id, dateRange) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.get(`api/cash/${id}/${Math.floor(dateRange[0] / 1000)}-${Math.floor(dateRange[1] / 1000)}`);
				resolve(res.data);
			} catch(err) {
				reject(err);
			}
		})
	}

	static getTopPlayers() {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.get(`api/top`);
				resolve(res.data);
			} catch(err) {
				reject(err);
			}
		})
	}

	static checkAuth() {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.get(`api/auth/status`);
				const data = res.data;

				if(data === "Unauthorized") resolve(false);
				else resolve({ id: data.id, avatar: data.avatar, username: data.username, allowed: data.allowed });
			} catch(err) {
				reject(err);
			}
		})
	}

	static logOut() {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.get(`api/auth/logout`);
				resolve(res.data);
			} catch(err) {
				reject(err);
			}
		})
	}

	static getLoginUrl() {
		return new Promise(async (resolve, reject) => {
			const response = await axios.get(`api/auth`);
			resolve(response.request.res.responseUrl);
		})
	}
}

export default PostService