<template>
	<div class="login-menu">
		<div class="content">

			<n-alert v-if="error != null" title="Eroare" type="error" style="margin-bottom: 3vh;">
		      {{ error }}
			</n-alert>

			<n-card>
				<center>
					<img width="200" src="../assets/logo.png">
					<h2 class="furyLogo">fury logs</h2>
					<n-button type="info" @click="logIn" color="#7289da">
						<template #icon>
							<n-icon>
								<i class="fa-brands fa-discord"></i>
							</n-icon>
						</template>
						Log In
					</n-button>
				</center>
			</n-card>
		</div>
	</div>
</template>

<script>

import PostService from '../PostService'

export default {
	name: 'LogIn',
	props: [
		'error'
	],
	methods: {
		async logIn () {
			window.location.href = "./api/auth";
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-menu {
	width: 100vw;
	height: 100vh;

	display: flex;
	justify-content: center;
	align-items: center;
}

.content {
	width: max(25vw, 600px);
}

@media only screen and (max-width: 1000px) and (orientation: portrait) {
	.content {
		width: 90vw;
	}
}
</style>
